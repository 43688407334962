import { defineStore } from 'pinia'
import { setI18nLanguage } from '@/locales';


const LangStore = defineStore('lang',  {
    state: () => {
        return {
            lang: 'zh-CN'
        }
    },
    actions: {
      async setLang(lang:string) {
        this.lang = lang
        setI18nLanguage(lang);
      },
    }
})


export default LangStore;

