import Container from '@/theme/components/Container/index.vue';
import Dict from '@/components/Dict/index.vue';

import { App } from 'vue';

const main = (app: App) => {
    app.component('b-container', Container);
    app.component('b-dict', Dict)
}
export default main;
