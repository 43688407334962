import { api } from './tokenApi';
import { ElMessage} from 'element-plus';
/** 发起请求，返回响应对象 */
export function getResponse(axiosOpts: Record<string, any>) {
  return api.request(axiosOpts, {}).getResponse();
}

/** 发起请求，返回响应内容，自动处理http错误 */
export function getBody(axiosOpts: Record<string, any>) {
  return api.request(axiosOpts, {}).getBody();
}

/** 发起请求，返回响应内容的data，自动处理业务错误 */
export function getData(axiosOpts: Record<string, any>) {
  return api.request(axiosOpts, {}).getData();
}

export async function download(url:string, params:Record<string, any>, fileName:string) {
  const res = await api.request({
    methods: 'get',
    url,
    params,
    responseType: 'blob',
  }).getResponse()
  const blob = new Blob([res.data], {
    // 创建一个新的Blob对象来接收后端的文件,这里第一个参数必须是数组类型，否则下载必出错。
    type: 'application/octet-stream',
    // type，表明该 Blob 对象所包含数据的 MIME 类型,这需要前后端统一规划
  });
  const link = document.createElement('a');
  const body:any = document.querySelector('body');
  link.href = window.URL.createObjectURL(blob); // 创建一个下载文件的URL，它指向blob，因为Blob对象在之前在接收后端发来的文件流信息。因为是Blob对象，所以不会跳转页面
  // 以后还有新增的东西的话再加
  link.download = fileName || 'system'; // 自己制定下载文件的文件名
  // 兼容火狐浏览器
  link.style.display = 'none';	// 让这个a标签不可见
  body.appendChild(link);
  link.click();		// 创建了新的a标签之后模拟点击事件，开始传输文件
  body.removeChild(link);	// 下载完成之后，移除按钮，垃圾回收，减少页面内存消耗
  window.URL.revokeObjectURL(link.href);	// 移除之前使用createObjectURL创建的URL，垃圾回收
}
export async function downloadPOST(url:string, params:Record<string, any>) {
  const res = await api.request({
    method: 'post',
    url,
    data: params,
    responseType: 'blob',
  }).getResponse();
  const { headers} = res
  let contentType=headers['content-type'];
  const blob = new Blob([res.data], {
    // 创建一个新的Blob对象来接收后端的文件,这里第一个参数必须是数组类型，否则下载必出错。
    type: contentType,
    // type，表明该 Blob 对象所包含数据的类型,这需要前后端统一规划
  });

  const contentDisposition = headers['content-disposition']
  if(!contentDisposition){
    return
  }
  let text=decodeURIComponent(contentDisposition.split('=')[1])
  const link = document.createElement('a');
  const body:any = document.querySelector('body');
  link.href = window.URL.createObjectURL(blob); // 创建一个下载文件的URL，它指向blob，因为Blob对象在之前在接收后端发来的文件流信息。因为是Blob对象，所以不会跳转页面
  // 以后还有新增的东西的话再加
  link.download = text; // 自己制定下载文件的文件名
  // 兼容火狐浏览器
  link.style.display = 'none';	// 让这个a标签不可见
  body.appendChild(link);
  link.click();		// 创建了新的a标签之后模拟点击事件，开始传输文件
  body.removeChild(link);	// 下载完成之后，移除按钮，垃圾回收，减少页面内存消耗
  window.URL.revokeObjectURL(link.href);	// 移除之前使用createObjectURL创建的URL，垃圾回收
}

export async function bcsdownload(url:string, params:Record<string, any>) {
  const res = await api.request({
    method: 'post',
    url,
    data: params,
    responseType: 'blob',
  }).getResponse();
  const { headers} = res
  let contentType=headers['content-type'];
  const blob = new Blob([res.data], {
    // 创建一个新的Blob对象来接收后端的文件,这里第一个参数必须是数组类型，否则下载必出错。
    type: contentType,
    // type，表明该 Blob 对象所包含数据的类型,这需要前后端统一规划
  });
  const reader = new FileReader();
  reader.onload =(event:any) =>{
    var textContent = event.target.result;
    var data = JSON.parse(textContent);
    var codeValue = data.code; 
    console.log(codeValue)
    var messageValue = data.message;
    try {
      var textContent = event.target.result;
    var data = JSON.parse(textContent);
    var codeValue = data.code; 
    console.log(codeValue)
    var messageValue = data.message;
      if(codeValue==500){
        ElMessage.warning(messageValue)
      }
      if(codeValue==400){
        ElMessage.warning(messageValue)
      }
    } catch (error) {
          console.error("Error parsing JSON data: ", error);
    }
  };
  reader.readAsText(blob);
  const contentDisposition = headers['content-disposition']
  if(!contentDisposition){
    return
  }
  let text=decodeURIComponent(contentDisposition.split('=')[1])
  const link = document.createElement('a');
  const body:any = document.querySelector('body');
  link.href = window.URL.createObjectURL(blob); // 创建一个下载文件的URL，它指向blob，因为Blob对象在之前在接收后端发来的文件流信息。因为是Blob对象，所以不会跳转页面
  // 以后还有新增的东西的话再加
  link.download = text; // 自己制定下载文件的文件名
  // 兼容火狐浏览器
  link.style.display = 'none';	// 让这个a标签不可见
  body.appendChild(link);
  link.click();		// 创建了新的a标签之后模拟点击事件，开始传输文件
  body.removeChild(link);	// 下载完成之后，移除按钮，垃圾回收，减少页面内存消耗
  window.URL.revokeObjectURL(link.href);	// 移除之前使用createObjectURL创建的URL，垃圾回收
}

export async function Filepreview(url:string, params:Object) {
  const res = await api.request({
    method: 'post',
    url,
    data: params,
    responseType: 'blob',
  }).getResponse();
  const { headers} = res
  let contentType=headers['content-type'];
  console.log(contentType)
  const readblob = new Blob([res.data]);
  const reader = new FileReader();
  reader.onload = function() {
    const arrayBuffer = reader.result;
    // 进一步处理 ArrayBuffer 或 Uint8Array 数据
    if(arrayBuffer!=null){
    const view = new DataView(arrayBuffer instanceof ArrayBuffer ? arrayBuffer : new TextEncoder().encode(arrayBuffer as string));
    // 读取文件的前几个字节
    const bytes = [];
    for (let i = 0; i < 4; i++) {
      bytes.push(view.getUint8(i).toString(16).padStart(2, '0'));
    }
    // 将字节转换为十六进制字符串
    const magicNumber = bytes.join('').toUpperCase();
    // 根据不同的文件类型进行判断
    switch (magicNumber) {
      case 'FFD8':
        contentType='image/jpeg';
        console.log( 'JPEG');
        break;
      case 'FFD8FFE0':
        contentType='image/jpeg';
        console.log( 'JPG');
        break;  
      case '89504E47':
        contentType='image/png';
        console.log( 'PNG');
        break;
      case '47494637':
        contentType='image/gif';
        console.log( 'GIF');
        break;
      case '424D':
        contentType='image/bmp';
        console.log( 'BMP');
        break;
      case '25504446':
        contentType='application/pdf';
        console.log( 'PDF');
        break;
      default:
        contentType=''
        console.log('Unknown');
        break;
    }
    if(contentType!=''){
      const blob = new Blob([res.data], {
        // 创建一个新的Blob对象来接收后端的文件,这里第一个参数必须是数组类型，否则下载必出错。
        type: contentType,
        // type，表明该 Blob 对象所包含数据的类型,这需要前后端统一规划
      });
   
      const urlmsg = URL.createObjectURL(blob);
    window.open(urlmsg);

          // 在新窗口中打开预览
      // window.open(urlmsg, '_blank');
      //     // 释放 URL 对象
      // URL.revokeObjectURL(urlmsg);
    }
  }
  };
 
  reader.readAsArrayBuffer(readblob);
}


export async function upload(url:string, file:File, params:Record<string, any>) {
  const formData = new FormData();
  formData.append('file', file);
  if (params) {
    for (const i in params) {
      formData.append(i, params[i]);
    }
  }

  const res = await api.request({
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url,
    data: formData,
  }).getResponse();

  return res.data;
}

export default getData;
export const request = getData;
