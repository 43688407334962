import { createApp } from 'vue';
import { createPinia } from 'pinia';
import persist from 'pinia-plugin-persistedstate';
import 'element-plus/dist/index.css'
// import 'element-plus/es/components/message/style/css.mjs';

import i18n from '@/locales';
import router from '@/router';
import initGlobalComponent from '@/plugins/components.global';
import initDirective from '@/plugins/directive.global';
import initProvide from '@/plugins/provide.global';
import { webLogin } from '@/utils/request/tokenApi';

import App from './App.vue';

const pinia = createPinia();
pinia.use(persist);

function runVueApp() {
  const app = createApp(App);

  app.use(pinia);
  app.use(i18n);
  app.use(router);

  // 依赖注入
  initProvide(app);
  // 全局组件
  initGlobalComponent(app);
  // 指令
  initDirective(app);

  app.mount('#app');
}

// sso
const sso = () => {
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const state = params.get('state');
  if (code && state) {
    webLogin(code, state).then((r:any) => {
      location.href = r.url;
    });
  } else {
    runVueApp();
  }
};

sso();
