export default {
  // common label
  'label.type': 'Type',
  'label.name': 'Name',
  'label.content': 'Content',
  'label.remark': 'Remark',
  'label.createTime': 'Create Time',
  'label.operation': 'Action',
  'label.code': 'Code',
  'label.date': 'Date',
  'label.title': 'Title',
  'label.status': 'Status',
  'label.department': 'Department',
  'label.endpoint': 'Endpoint',
  'label.username': 'Username',
  'label.unitName': 'UnitName',
  'label.UID': 'UID',
  'label.email': 'Email',
  'label.mobile': 'Mobile',
  'label.yes': 'Yes',
  'label.no': 'No',
  'label.logout': "Logout",
  'label.import': 'Import',
  'label.export': 'Export',
  'label.delete': 'Delete',

  // common button
  'button.search': 'Search',
  'button.reset': 'Reset',
  'button.cancel': 'Cancel',
  'button.save': 'Save',
  'button.import': 'Import',
  'button.export': 'Export',
  'button.add': 'Add',
  'button.submit': 'Submit',
  'button.ok': 'OK',
  'button.synchronize': 'Synchronize',

  // common message
  'message.add': 'Add Successfully.',
  'message.edit': 'Save Successfully.',
  'message.delete.confirm': 'Are you sure to delete this record?',
  'message.delete.success': 'Delete record success.',
  'message.rule': 'field format is wrong。',
  'message.read.success': 'Operate Successful.',
  'message.async.title': 'Are you sure to synchronize permissions?',
  'message.async.success': 'Synchronize Successful',
  'message.back': 'Back to home',

  // table label
  'label.table.title': 'The result include',
  'label.table.items': 'items',
  'label.table.edit': 'Edit',
  'label.table.delete': 'Delete',
  'label.table.detail': 'Detail',
  'label.table.permission': 'Permission',
  'label.permission': 'Button Permissions',
  // placeholder
  'placeholder.select': 'Please Select',
  'placeholder.input': 'Please Input',
  'placeholder.Startdate': 'Start date',
  'placeholder.Enddate': 'End date',

  // Audit
  'label.audit.detail': 'Audit Detail',

  'column.title':'xiugai',
  // Delegation
  'label.delegation.id': 'Delegate UID',
  'label.delegation.name': 'Delegate Name',
  'label.delegation.list': 'Delegate List',
  'label.delegation.startDate': 'Start Date',
  'label.delegation.endDate': 'End Date',
  'label.delegation.add': 'Add Delegation',
  'label.delegation.edit': 'Edit Delegation',

  'label.delegation.authorizer.id': 'Authorizer UID',
  'label.delegation.authorizer.name': 'Authorizer  Name',

  'rule.delegation.id': 'Delegation id can not be empty!',
  'rule.delegation.startTime': 'Start time can not be empty!',
  'rule.delegation.endTime': 'End time can not be empty!',

  // Dict
  'label.dict.table': 'Dict table',
  'label.dict.key': 'Key',
  'label.dict.cn': 'CN',
  'label.dict.en': 'EN',
  'label.dict.add': 'Add Dict',
  'label.dict.edit': 'Edit Dict',
  'label.table.notes': 'Notes',
  'rule.dict.code': 'Please input dict code',
  'rule.dict.name': 'Please input dict name',

  // Notification
  'label.notification.handle': 'Link',
  'label.notification.ignore': 'Mark read',
  'label.notification.read': 'Read',
  'label.notification.unread': 'Unread',
  'label.notification.clear': 'All Read',
  'label.notification.more': 'More',

  // Department
  'label.department.title': 'View Department',
  'label.department.unit.name': 'Unit name',
  'label.department.unit.id': 'Unit id',
  'label.department.short.name': 'Short name',
  'label.department.parent.id': 'Parent id',

  // permission
  'label.permission.name': 'Permission Name',
  'label.permission.code': 'Permission Code',
  'label.permission.action': 'Permission Action',
  'label.permission.data': 'Data Permissions',
  'label.permission.title': 'Permissions',
  'label.permission.description': 'Permission Description',

  // Role
  'label.role.code': 'Role Code',
  'label.role.name': 'Role Name',
  'label.role.isGlobal': 'Global Role',
  'label.role.enabled': 'Enabled',
  'label.role.disabled': 'Disabled',
  'label.role.description': 'Role Description',
  'label.role.add': 'Add role',
  'label.role.edit': 'Edit role',
  'label.role.permission': 'Edit permission',

  'rule.role.code': 'Role code can not be empty!',
  'rule.role.name': 'Role name can not be empty!',
  'rule.role.model': 'Company can not be empty!',

    // user
    'label.user.add': 'Add user',
    'label.user.edit': 'Edit user',
    'label.user.roles': 'Role List',
  
    'rule.user.name': 'Username can not be empty!',
    'rule.user.uid': 'Uid can not be empty!',
    'rule.user.deptCode': 'DepartmentName can not be empty!',
    'rule.user.email': 'Email can not be empty!',
    'rule.user.email.type': 'Incorrect email format!',
    'rule.user.mobile': 'Mobile can not be empty!',
    'rule.user.mobile.type': 'Incorrect phone number format!',

  // container
  'label.menu.search': 'Query Conditions',
  'label.menu.table': 'Query Result',
  'label.menu.close': 'Close',
  'label.menu.open': 'Expand',
  // job manager
  
  'label.jobManager.jobName':'JobName',
  'label.jobManager.jobGroup':'JobGroup',
  'label.jobManager.cron':'Cron',
  'label.jobManager.description':'Description',
  'label.jobManager.status':'Status',
  'label.jobManager.misfireType':'Misfire Type',
  'label.jobManager.schedulerJobStatus':'Job Running Status',
  'button.jobManager.delete': 'delete',
  'button.jobManager.pause': 'pause',
  'button.jobManager.resume': 'resume',
  'button.jobManager.runOnce': 'run once',
  'label.jobManager.add': 'Add Job',
  'rule.jobManager.jobName': 'JobName is required!',  
  'rule.jobManager.jobGroup': 'JobGroup is required!',  
  'rule.jobManager.cron': 'Cron is required!',  
  'rule.jobManager.misfireType': 'Misfire Type is required!',  
  'rule.jobManager.status': 'Status is required!',    
  'rule.jobManager.jobMapKey': 'Key is required!',  
  'rule.jobManager.jobMapValue': 'Value is required!',  
  'label.jobManager.enabled': 'enabled',
  'label.jobManager.disabled': 'disabled',
  'label.jobManager.jobMaps': 'job map',
  'label.jobManager.misfireType.MISFIRE_INSTRUCTION_IGNORE_MISFIRE_POLICY': 'MISFIRE_INSTRUCTION_IGNORE_MISFIRE_POLICY',
  'label.jobManager.misfireType.MISFIRE_INSTRUCTION_FIRE_ONCE_NOW': 'MISFIRE_INSTRUCTION_FIRE_ONCE_NOW',
  'label.jobManager.misfireType.MISFIRE_INSTRUCTION_DO_NOTHING': 'MISFIRE_INSTRUCTION_DO_NOTHING',
  'message.jobManager.add': 'Add Successfully.',
  // menu
  'menu.home': 'Home',
  'menu.system': 'BCS Custom',
  'menu.system.rbac': 'RBAC',
  'menu.rbac.user': 'User',
  'menu.rbac.role': 'Role',
  'menu.rbac.permission': 'Permission',
  'menu.rbac.department': 'Department',
  'menu.system.notification': 'Notification',
  'menu.system.dictionary': 'Data Dictionary',
  'menu.system.audit': 'Audit',
  'menu.system.delegation': 'Delegation',
  'menu.system.notFound': 'Not Found',
  'menu.system.customs':'Parts Declaration',
  'menu.cus.rack':'RACK Tracking',
  'menu.system.jobManager':'Job Manager',
   'menu.system.dpcard':'危包证追踪',
   'menu.dpcard.ledger':'工厂包装信息确认',
   'menu.dpcard.tocq':'危包证追踪',
  'menu.system.filemg':'文件管理',
  'menu.filemg.filemg':'文件管理',
  'menu.filemg.dpEPart':'文件上传',
  'menu.filemg.dpSeach':'文件查询',
  'menu.filemg.pdoc':'性能单管理',
  'menu.filemg.dpInspledger':'查验台账',
  'menu.filemg.dpFReport':'厂检报告1',
  'menu.system.psettings':'参数设置',
  'menu.psettings.dppqconfig':'包装-零件参数设置',
  'menu.system.dpexport':'原产地证管理',
  'menu.dpexport.dpEPart':'原产地证管理',
  'menu.system.dpShipNoCertificate':'危包证申请',
  'menu.dpShipNoCertificate.dpShipNotice':'工厂出货通知',
  'menu.dpShipNoCertificate.dpGoodsCert':'危包证申请',
  'label.InvoiceNO': 'Invoice NO.',
  'label.CDF':'CDF',
  'label.Expiredate':'Expire date',
  'label.CDD':'CDD',
  'label.ReturnCDD'	:'Return-CDD',
  'label.Alertdate':'Alert date',
  'label.CarModel':'Car Model',
  'label.WaybillNO':'Waybill NO.',
  'label.Type/LOT':'Type/LOT',
  'label.ContainerNO':'Container NO.',
  'label.CDFNO':'CDF NO.',
  'label.ReturnWaybillNO':'Return-Waybill NO.',
  'label.ReturnContainerNO':'Return-Container NO.',
  'label.ReturnCDFNO':'Return-CDF NO.',
  'label.Return':'Return Batch',
  'label.PartNO':'Part NO.',
  "label.ExportReviewResult":'Export Review Result',
  "label.ReturnImportReviewResult":'Return Import Review Result',
  "label.ExportResult":'Export Review',
  "label.ReturnResult":'Import Review',
  'label.Notes':'Notes',
  "label.DescriptionEN":"Description (EN)",
  "label.DescriptionCN":'Description (CN)',
  "label.DataStatus":"Data Status",
  "label.singinid":"SIGNI ID",
  "label.SerialNumber":'Serial Number',
  "label.DownloadTemplate":'Download Template',
  "label.UpdateBatch":'Update Batch',
  "label.MarkasCompleted":'Mark as Completed',
  "label.Delete":'Delete',
};
