/*
 * @Description: static routes
 */

//! If unnecessary, please don't set name in root, otherwise it will be shown in breadcrumb.

export default [
  {
    root: {},
    meta: { alive: false },
    path: '/',
    hidden: false,
    redirect: '/home',
    component: 'layout',
    permissions: [],
    children: [
      {
        name: 'home',
        meta: { alive: false, fullScreen: true },
        path: '/home',
        hidden: false,
        component: 'home/index.vue',
        permissions: [],
      },
      {
        name: 'system',
        meta: { alive: false },
        path: '/system',
        hidden: false,
        redirect: '/system/cus/rack',
        component: 'RouterView',
        permissions: [],
        children: [
          {
            name: 'system.customs',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: false },
            path: '/system/cus',
            component: 'RouterView',
            redirect:'/system/cus/rack',
            permissions: [],
            children: [
              {
                name: 'cus.rack',
                meta: { alive: false },
                path: '/system/cus/rack',
                hidden: false,
                component: 'system/cus/rack/rack.vue',
                permissions: ['rack.query'],
              }
            ],
          },
          {
            name: 'system.dpcard',
            meta: { defaultIcon: 'Discount', activeIcon: 'DelegationFilled', alive: false },
            path: '/system/dpcard',
            component: 'RouterView',
            hidden: false,
            redirect:'/system/dpcard/ledger',
            permissions: [],
            children: [
              {
                name: 'dpcard.ledger',
                meta: { alive: false },
                path: '/system/dpcard/ledger',
                hidden: false,
                component: 'system/dpcard/ledger/LedgerList.vue',
                permissions: ['ledger.query'],
              },
              {
                name: 'dpcard.tocq',
                meta: { alive: false },
                path: '/system/dpcard/tocq',
                hidden: false,
                component: 'system/dpcard/tocq/TocqList.vue',
                permissions: ['tocq.query'],
              }
            ],
          },
          {
            name: 'system.filemg',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: false },
            path: '/system/filemg',
            hidden: false,
            component: 'RouterView',
            redirect:'/system/filemgs/dpFileUpload',
            permissions: [],
            children:[
              {
                name: 'filemg.dpEPart',
                meta: { alive: false },
                path: '/system/filemgs/dpFileUpload',
                hidden: false,
                component: 'system/filemg/dpFileUpload/DpexportpartList.vue',
                permissions: ['dpFileUpload.query'],
              },
              {
                name: 'filemg.dpSeach',
                meta: { alive: false },
                path: '/system/filemgs/dpFileSearch',
                hidden: false,
                component: 'system/filemg/dpFileSearch/DpFileSearchList.vue',
                permissions: ['dpFileSearch.query'],
              }
              // {
              //   name: 'filemg.filemg',
              //   meta: { alive: false },
              //   path: '/system/filemgs/filemg',
              //   hidden: false,
              //   component: 'system/filemg/fileinfo/FilemgList.vue',
              //   permissions: ['filemg.query'],
              // },
              
              
              // {
              //   name: 'filemg.dpFReport',
              //   meta: { alive: false },
              //   path: '/system/filemgs/dpFReport',
              //   hidden: false,
              //   component: 'system/filemg/dpFReport/DpFReportList.vue',
              //   permissions: ['dpFReport.query'],
              // }
            ]
          },
          // {
          //   name: 'system.dpexport',
          //   meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: false },
          //   path: '/system/dpexport',
          //   hidden: false,
          //   component: 'RouterView',
          //   redirect:'/system/dpexport/dpEPart',
          //   permissions: [],
          //   children:[
          //     {
          //       name: 'dpexport.dpEPart',
          //       meta: { alive: false },
          //       path: '/system/dpexport/dpEPart',
          //       hidden: false,
          //       component: 'system/dpexport/dpexportpart/DpexportpartList.vue',
          //       permissions: ['dpEPart.query'],
          //     },
          //   ]
          // },
          {
            name: 'system.dpShipNoCertificate',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: false },
            path: '/system/dpShipNoCertificate',
            hidden: false,
            component: 'RouterView',
            redirect:'/system/dpShipNoCertificate/dpShipNotice',
            permissions: [],
            children:[
              {
                name: 'filemg.pdoc',
                meta: { alive: false },
                path: '/system/filemgs/pdoc',
                hidden: false,
                component: 'system/filemg/pdoc/PdocList.vue',
                permissions: ['pdoc.query'],
              },
              {
                name: 'dpShipNoCertificate.dpShipNotice',
                meta: { alive: false },
                path: '/system/dpShipNoCertificate/dpShipNotice',
                hidden: false,
                component: 'system/dpShipNoCertificate/dpShipNotice/DpShipNoticeList.vue',
                permissions: ['dpShipNotice.query'],
              },
              {
                name: 'filemg.dpInspledger',
                meta: { alive: false },
                path: '/system/filemgs/dpInspledger',
                hidden: false,
                component: 'system/filemg/dpInspledger/DpInspledgerList.vue',
                permissions: ['dpInspledger.query'],
              },
              {
                name: 'dpShipNoCertificate.dpGoodsCert',
                meta: { alive: false },
                path: '/system/dpShipNoCertificate/dpGoodsCert',
                hidden: false,
                component: 'system/dpShipNoCertificate/dpGoodsCert/DpGoodsCertList.vue',
                permissions: ['dpGoodsCert.query'],
              },
            ]
          },
          {
            name: 'system.psettings',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: false },
            path: '/system/psettings',
            component: 'RouterView',
            hidden: false,
            redirect:'/system/psettings/dppqconfig',
            permissions: [],
            children: [
              {
                name: 'psettings.dppqconfig',
                meta: { alive: false },
                path: '/system/psettings/dppqconfig',
                hidden: false,
                component: 'system/psettings/dppqconfig/DppqconfigList.vue',
                permissions: ['dppqconfig.query'],
              }
            ],
          },
          {
            name: 'system.rbac',
            meta: { defaultIcon: 'Seting', activeIcon: 'SetingFilled', alive: false },
            path: '/system/rbac',
            hidden: false,
            redirect: '/system/rbac/user',
            component: 'RouterView',
            permissions: [],
            children: [
              {
                name: 'rbac.user',
                meta: { alive: false },
                path: '/system/rbac/user',
                hidden: false,
                component: 'system/rbac/user/UserList.vue',
                permissions: ['user.query'],
              },
              {
                name: 'rbac.role',
                meta: { alive: false },
                path: '/system/rbac/role',
                hidden: false,
                component: 'system/rbac/role/RoleList.vue',
                permissions: ['role.query'],
              },
              {
                name: 'rbac.permission',
                meta: { alive: false },
                path: '/system/rbac/permission',
                hidden: false,
                component: 'system/rbac/permission/PermissionList.vue',
                permissions: ['permission.query'],
              },
              {
                name: 'rbac.department',
                meta: { alive: false },
                path: '/system/rbac/department',
                hidden: false,
                component: 'system/rbac/department/DepartmentList.vue',
                permissions: ['department.query'],
              },
            ],
          },
          {
            name: 'system.notification',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: false },
            path: '/system/notification',
            hidden: false,
            component: 'system/notification/NotificationList.vue',
            permissions: ['notification.query'],
          },
          {
            name: 'system.dictionary',
            meta: { defaultIcon: 'DataDictionary', activeIcon: 'DataDictionaryFilled', alive: false },
            path: '/system/dict',
            hidden: false,
            component: 'system/dict/DictList.vue',
            permissions: ['dict.query'],
          },
          {
            name: 'system.audit',
            meta: { defaultIcon: 'Audit', activeIcon: 'AuditFilled', alive: false },
            path: '/system/audit',
            hidden: false,
            component: 'system/audit/AuditList.vue',
            permissions: ['audit.query'],
          },
          {
            name: 'system.delegation',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: false },
            path: '/system/delegation',
            hidden: false,
            component: 'system/delegation/DelegationList.vue',
            permissions: ['delegation.query'],
          },
          {
            name: 'system.jobManager',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: false },
            path: '/system/jobManager',
            hidden: false,
            component: 'system/jobManager/JobManagerList.vue',
            permissions: ['job-Manager.query'],
          },
 
        ],
      },
      {
        name: 'system.notFound',
        meta: { icon: '' },
        path: '/404',
        hidden: true,
        component: 'exception/404.vue',
        permissions: [],
      },
    ],
  },
];
