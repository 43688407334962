<template>
    <a-select v-model="selfValue" @select="change" :placeholder="placeholder">
        <a-select-option v-for="item in dictOptions" :key="item.itemKey" :value="item.itemKey">
            {{ item.itemValue }}
        </a-select-option>
    </a-select>
</template>

<script lang="ts">
// 接口
import { getDictionaryByKey } from '@/api/system/meta';

export default {
  name: 'b-dict',
  model: {
    prop: 'value',
    event: 'onChange',
  },
  props: {
    dictKey: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
      default: 'Please Select',
    },
  },
  data() {
    return {
      selfValue: '',
      dictOptions: [] as any[],
    };
  },
  methods: {
    getOptions(key: any) {
      getDictionaryByKey(key).then((data) => {
        this.dictOptions = data;
      });
    },
    init() {
      this.selfValue = this.value as string;
      console.log(this);
      if (this.dictKey) {
        this.getOptions(this.dictKey);
      }
    },
    change(val: any) {
      this.$emit('onChange', val);
    },
  },
  watch: {
    dictKey(val, oldVal) {
      if (val !== oldVal) {
        this.getOptions(val);
      }
    },
    value(val) {
      if (val !== this.selfValue) {
        this.selfValue = val;
      }
    },
  },
  created() {
    this.init();
  },
};
</script>
