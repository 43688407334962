/*
 name：字段必须唯一
 path: 尽量写成带/的
 hidden； 一级目录是否显示
 meta: hasPermissions字段会被注入，用来判断是否有权限
       icon: 图标
       alive: 是否缓存
       fullScreen:是否是全屏页面
 component: '存在layout字段，自动匹配到layout文件夹下，否则就是view目录下'
 permissions: '权限标识，用来和current-user接口做匹配，current-user返回字段和标识比对，有就证明有权限'
 redirect： 重定向
 children：子集目录
*/
import RouterView from '@/theme/RouterView.vue';
import { shallowRef } from 'vue';
import _ from 'lodash';
import { RouteRecordRaw } from 'vue-router';
import * as icons from './router.icon';
import routers from '../router.config';
// console.log(String(Object.keys(icons)))
const ctx: any = {
  RouterView,
  layout: () => import('@/theme/index.vue'),
};

const views = import.meta.glob('/src/views/**/*.vue');

function traverse(treeData: any[]) {
  const arr = treeData.slice();

  while (arr.length > 0) {
    const item = arr.pop();

    if (ctx[item.component]) {
      item.component = ctx[item.component];
    } else {
      item.component = views[`/src/views/${item.component}`];
    }

    if (item.meta.icon) {
      const icon = _.get(icons, item.meta.icon);
      if (icon) {
        item.meta.icon = shallowRef(icon);
      }
    }
    if (item.meta.defaultIcon) {
      const defaultIcon = _.get(icons, item.meta.defaultIcon);
      if (defaultIcon) {
        item.meta.defaultIcon = shallowRef(defaultIcon);
      }
      const activeIcon = _.get(icons, item.meta.activeIcon);
      if (activeIcon) {
        item.meta.activeIcon = shallowRef(activeIcon);
      }
    }

    if (item.children) {
      for (const child of item.children) {
        arr.push(child);
      }
    }
  }

  return treeData;
}
const Routes = traverse(routers);

export const setDocumentTitle = (title:string, prefixed:string) => {
  document.title = title;
  if (prefixed) {
    document.title = `${title} - ${prefixed}`;
  }
  const ua = navigator.userAgent;
  // eslint-disable-next-line
  const regex = /\bMicroMessenger\/([\d\.]+)/;
  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const i = document.createElement('iframe');
    i.src = '/favicon.ico';
    i.style.display = 'none';
    const time = 9;
    i.onload = () => {
      setTimeout(() => {
        i.remove();
      }, time);
    };
    document.body.appendChild(i);
  }
};

// 对路由权限进行判定
export function permissionRouters(routers:RouteRecordRaw[], permissions:Record<string, any>[]) {
  // 构建所有权限数组:例子['user.query','Dict.query','user.add']
  const permissionsList:string[] = [];
  for (const index in permissions) {
    for (const i in permissions[index].actions) {
      permissionsList.push(`${permissions[index].permission}.${permissions[index].actions[i]}`);
    }
  }
  const loopRouters = (routers:RouteRecordRaw[]) => {
    const newRoutes:RouteRecordRaw[] = [];
    routers.forEach((e:any) => {
      // 判断权限数组里是否包含router对象的permissions字符串
      if (e.children && e.children.length) {
        e.children = loopRouters(e.children);
      }
      // 查找permissions数组中的xx.query权限
      let permissionLabel:string = '';
      for (const index in e.permissions) {
        if (e.permissions[index].indexOf('.query')) {
          permissionLabel = e.permissions[index];
        }
      }
      if (permissionsList.indexOf(permissionLabel) !== -1 || e.permissions.length === 0) {
        newRoutes.push(e);
      }
    });
    return newRoutes;
  };
  return loopRouters(routers);
}

export default Routes;
