import axios from 'axios';
import { ElNotification } from 'element-plus';
import 'element-plus/es/components/notification/style/css.mjs';

// #region config

const NAME = 'CIEAF_DEMO';

const KEY_TOKEN = `${NAME}_token`; // localstorage中存储的键名，该键值存储token信息
const KEY_STATE = `${NAME}_state`; // localstorage中存储的STATE，该键值存储IAM登录前的state用于对比

const ENV = import.meta.env;

const REDIRECT_URL = `${ENV.VITE_APP_PUBLIC_PATH}`; // SSO登录成功跳转地址 / joychat验证失败跳转地址
const API_SSO_URL = '/sso/url/wen'; // 获取SSO登录跳转地址的api
const API_CREATE_TOKEN_BY_CODE = '/sso/token/wen'; // 初始化token的api。web携带code，app携带ticket
const API_REFRESH_TOKEN = '/sso/refresh'; // 刷新token的api，携带refreshToken
const API_LOGOUT = "/sso/logout/wen";                                   // 退出登录地址

const API_DEBUG = ENV.VITE_DEBUG; // 请求根路径

var _vdomain = document.domain; 
var _vprotocol = document.location.protocol; 
var localUrl = _vprotocol + "//" + _vdomain +"/"+ ENV.VITE_APP_API_BASE_URL; // 请求根路径


const BASE_URL = API_DEBUG=='true' ? ENV.VITE_APP_API_BASE_URL:localUrl; // 请求根路径


// if(API_DEBUG){
//   console.log("remote ",BASE_URL);
//   BASE_URL = ENV.VITE_APP_API_BASE_URL; // 请求根路径
// }else{
//   console.log("local ",BASE_URL);
//   var _vdomain = document.domain; 
//   var _vprotocol = document.location.protocol; 
//   BASE_URL = _vprotocol + "//" + _vdomain ; // 请求根路径
//   axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'; 
// }

const AXIOS_TIMEOUT = 60000; // 请求超时时长
const LOCK_TIMEOUT = 10000; // 锁定token超时时长

const ERR_HANDLER = (type: string, detail: Record<string, any> = {}) => { // 提示错误
  switch (type) {
    case 'LOGIN_STATE_ERROR': {
      ElNotification({  message: 'LOGIN STATE ERROR', type: 'error' });
      break;
    }
    case 'RESPONSE_ERROR': {
      const { code, status, statusText } = detail;
      ElNotification({ title: `HTTP[${status}]`, type: 'error' });
      break;
    }
    // case 'NET_ERROR': {
    //   const { code } = detail;
    //   ElNotification({ title: `${code}`, type: 'error' });
    //   break;
    // }
    case 'SERVE_ERROR': {
      const { message, code } = detail;
      ElNotification({ message: `${message}`, type: 'error' });
      break;
    }
    default: {
      ElNotification({ message: `${detail}`, type: 'error' });
      break;
    }
  }
};

// #endregion

// #region myAxios

const myAxios = axios.create({ baseURL: BASE_URL, timeout: AXIOS_TIMEOUT });

/* 请求拦截 */
myAxios.interceptors.request.use((config:any) => config, (err:any) => Promise.reject(err));

/* 响应拦截 */
myAxios.interceptors.response.use((response:any) => response, (err:any) => Promise.reject(err));

// #endregion

// #region utils

/** 等待几毫秒 */
function waitMs(ms: number = 0) {
  return new Promise((next) => setTimeout(next, ms));
}

/** 操作localStorage.KEY_TOKEN val:undefined-取值 json-赋值 null-删除 */
export function storage(val: undefined | Record<string, any> | null = undefined) {
  if (val === undefined) {
    const v = storage.get(KEY_TOKEN);
    return v ? JSON.parse(v) : null;
  }
  if (val === null) {
    storage.remove(KEY_TOKEN);
  } else {
    storage.set(KEY_TOKEN, JSON.stringify(val));
  }
}

const store = window.localStorage;
storage.get = (k: string) => store.getItem(k);
storage.set = (k: string, v: any) => store.setItem(k, v);
storage.remove = (k: string) => store.removeItem(k);

// #endregion

// #region tooken

/** 获取accessToken */
async function getAccessToken() {
  let token1: Record<string, any> | null;

  do {
    // localstorage中没有痕迹，说明没登录过
    // 抛出token错误，触发sso重新登录
    token1 = storage();
    if (!token1) {
      storage({ lock: Date.now() + LOCK_TIMEOUT });
      throw tokenError('token in storage is empty');
    }

    // 如果token被锁，等待
    if (token1.lock) {
      if (token1.lock < Date.now()) {
        // 锁的时间太长，有可能是别的tab页面加锁后被终止了
        // 抛出token错误，触发sso重新登录
        throw tokenError('token lock is timeout');
      }
      await waitMs(500);
    }
  } while (token1.lock);

  // 有效期内，返回accessToken
  const now = Date.now();
  const { access, expire, refresh } = token1;
  if (expire > now) {
    return access;
  }

  // 过期需要刷新，先锁token
  storage({ lock: now + LOCK_TIMEOUT });

  // 刷新token
  const token2 = await fetchToken(API_REFRESH_TOKEN, { refreshToken: refresh })
    .catch((err) => {
      responseError(err);
      // 当错误没有触发sso登录（非401）
      // 解锁，并还原之前的token，下把继续用
      if (!err.toSSO) {
        storage(token1);
        return Promise.reject(err);
      }
      return err.toSSO.then((r: any) => r || Promise.reject(err));
    });
  storage(token2);
  return token2.access;
}

/** 从API中获取token */
async function fetchToken(url: string, data: Record<string, any>) {
  const res = await myAxios.post(url, data);

  const { accessToken, refreshToken, accessExpire } = res.data;
  return {
    access: String(accessToken),
    refresh: String(refreshToken),
    expire: Date.now() + Number(accessExpire) * 1000,
  };
}

/** 生成token错误 */
function tokenError(message: string) {
  const err = Error(message);
  err.name = 'tokenError';
  return err;
}

// #endregion

// #region login

/** 登录sso系统 */
async function ssoLogin() {
  // 清空状态
  storage(null);

  // 向后端请求跳转地址
  const redirectUrl = REDIRECT_URL.replace(/^\//, `${location.origin}/`);
  const apiUrl = `${API_SSO_URL}?redirectUrl=${encodeURIComponent(redirectUrl)}&currentPage=${encodeURIComponent(encodeURIComponent(location.href))}`;
  const res = await myAxios.get(apiUrl)
    .catch((err:any) => {
      responseError(err, { 401: () => { alert('此接口不应该出现401错误，请联系管理员'); } });
      return Promise.reject(err);
    });
  const {
    url, accessToken, refreshToken, accessExpire,
  } = res.data;

  if (accessToken) {
    // 如果返回了token，直接存储使用
    const mockToken = {
      access: String(accessToken),
      refresh: String(refreshToken),
      expire: Date.now() + Number(accessExpire) * 1000,
    };
    storage(mockToken);
    console.warn('[CIEAF] MOCK USER START');
    window.location.reload();
  } else {
    // 记录state，为了返回时验证来源
    const params = new URLSearchParams(url);
    const state = params.get('state') || '';

    const saveState = state.split('|')[1];
    storage.set(KEY_STATE, saveState);

    // 转到sso登录地址
    window.location.href = url;
  }
}

/** 登录WEB系统，获取token，返回转向地址 */
export async function webLogin(code: string, state: string) {
  const state1 = state.split('|')[1];
  const state2 = storage.get(KEY_STATE);
  if (state1 !== state2) {
    ERR_HANDLER('LOGIN_STATE_ERROR');
    storage(null);
    throw Promise.reject('LOGIN_STATE_ERROR');
  }

  const [url, uid] = state.split('|');
  const redirectUrl = REDIRECT_URL.replace(/^\//, `${location.origin}/`);
  const token = await fetchToken(API_CREATE_TOKEN_BY_CODE, { code, state: uid, redirectUrl })
    .catch((err) => {
      responseError(err);
      return Promise.reject(err);
    });

  storage(token);

  return { url: decodeURIComponent(url) };
}
/** 登出 */
export async function webLogout() {
  const api = new Api({ url: API_LOGOUT, method: "get" });
  const { logoutUrl } = await api.getBody();
  location.href = `/`;
}
// #endregion

// #region API

let apiIndex = 0;

class Api {
  private readonly axiosOpts: Record<string, any>;

  private readonly apiOpts: Record<string, any>;

  constructor(axiosOpts: Record<string, any>, apiOpts: Record<string, any> = {}) {
    this.axiosOpts = axiosOpts;
    this.apiOpts = apiOpts;
  }

  /**
     * 开始/结束请求时调用
     * 可重写该方法实现节流
     * @typedef { (run:boolean, runId:number)=>void } Handler
     * @type {Handler}
     */
  // eslint-disable-next-line class-methods-use-this
  handler(run: boolean, runId: string) {
    // console.log( `api-${runId}:${run ? 'run' : 'end'}` );
  }

  /**
     * 获取响应对象 Response
     * token错误时会跳转到sso登录
     * @param { Handler } [handler]
     * @returns { Promise<Response> }
     */
  async getResponse(handler?: any) {
    // 自定义节流、公共节流、Set快捷节流操作
    let handlerFunc = handler || this.handler;
    if (handlerFunc instanceof Set) {
      const set = handlerFunc;
      handlerFunc = (state: boolean, id: string) => {
        set[state ? 'add' : 'delete'](id);
      };
    }

    // 开启节流
    const index = ++apiIndex;
    handlerFunc(true, index);

    // 获取accessToken
    let accessToken;
    if (!this.apiOpts.ignoreToken) {
      accessToken = await getAccessToken()
        .catch(async (err) => {
          handlerFunc(false, index);
          // 只有token错误触发sso登录
          if (err.name === 'tokenError') { await ssoLogin(); }
          return Promise.reject(err);
        });
    }

    // 准备请求
    const axiosOpts = { ...this.axiosOpts };
    axiosOpts.headers = {
      'access-token': accessToken,
      'Content-Type': 'application/json',
      'Accept-Language':'zh-CN',
      ...axiosOpts.headers,
    };

    // 发起请求
    const core = this.apiOpts.core || myAxios;
    const res = await core(axiosOpts)
      .catch((err: any) => {
        handlerFunc(false, index);
        return Promise.reject(err);
      });

    // 关闭节流 为了串联请求时动画更流畅，加一个延时
    setTimeout(() => {
      handlerFunc(false, index);
    }, 100);
    return res;
  }

  /**
     * 获取响应体，Response.body
     * 自动处理httpStatus不为2xx的错误
     * @param { Handler } [handler]
     * @returns { Promise<any> }
     */
  async getBody(handler?: any) {
    const res = await this.getResponse(handler)
      .catch((err) => {
        responseError(err);
        return Promise.reject(err);
      });

    return res.data;
  }

  /**
     * 获取响应结果，Response.body.data
     * 自动处理httpStatus不为2xx的错误
     * 自动处理json.errcode不为0的错误
     * @param { Handler } [handler]
     * @returns { Promise<any> }
     */
  async getData(handler?: any) {
    const body = await this.getBody(handler);
    const { code, data, message } = body;
    if (code !== 200) {
      const err = serveError(code, message);
      return Promise.reject(err);
    }
    return data;
  }
}

export const api = {
  request: (axiosOpts: Record<string, any>, apiOpts?: Record<string, any>) => new Api({ ...axiosOpts }, apiOpts),
  get: (url: string, axiosOpts?: Record<string, any>, apiOpts?: Record<string, any>) => new Api({
    ...axiosOpts,
    url,
    method: 'get',
  }, apiOpts),
  delete: (url: string, axiosOpts?: Record<string, any>, apiOpts?: Record<string, any>) => new Api({
    ...axiosOpts,
    url,
    method: 'delete',
  }, apiOpts),
  head: (url: string, axiosOpts?: Record<string, any>, apiOpts?: Record<string, any>) => new Api({
    ...axiosOpts,
    url,
    method: 'head',
  }, apiOpts),
  options: (url: string, axiosOpts?: Record<string, any>, apiOpts?: Record<string, any>) => new Api({
    ...axiosOpts,
    url,
    method: 'options',
  }, apiOpts),
  post: (url: string, data?: Record<string, any>, axiosOpts?: Record<string, any>, apiOpts?: Record<string, any>) => new Api({
    ...axiosOpts, url, data, method: 'post',
  }, apiOpts),
  put: (url: string, data?: Record<string, any>, axiosOpts?: Record<string, any>, apiOpts?: Record<string, any>) => new Api({
    ...axiosOpts, url, data, method: 'put',
  }, apiOpts),
  patch: (url: string, data?: Record<string, any>, axiosOpts?: Record<string, any>, apiOpts?: Record<string, any>) => new Api({
    ...axiosOpts, url, data, method: 'patch',
  }, apiOpts),
};

// #endregion

// #region error

/** 处理响应错误 */
function responseError(err: Record<string, any>, handlers: Record<string, any> = {}) {
  const {
    name, code, response, used,
  } = err;

  // getResponseBody的响应错误可能来自fetchToken
  // 该错误已经在getAccessToken中处理过
  // 应避免重复处理
  if (used) return;
  err.used = true;

  if (name === 'AxiosError') {
    // 响应错误处理
    // axios的0.x和1.x的错误处理模式不一样。QTMD
    if (response && response.status) {
      const { status, statusText } = response;
      if (typeof handlers[status] === 'function') {
        handlers[status]();
        return;
      }
      switch (status) {
        case 401:
          // 标记已经由ssoLogin处理后续内容，无需还原现场
          err.toSSO = ssoLogin();
          break;
        default:
          ERR_HANDLER('RESPONSE_ERROR', { code, status, statusText });
          break;
      }
    } else {
      // 网络连接错误
      ERR_HANDLER('NET_ERROR', { code });
    }
  }
}

/** 处理业务错误 */
function serveError(code: number, message: string) {
  ERR_HANDLER('SERVE_ERROR', { message, code });
  const err = Error(message);
  err.name = 'SERVE_ERROR';
  return err;
}

// #endregion
