import { defineStore } from 'pinia'
import router from "@/router";
import {RouteRecordRaw} from "vue-router";

const CommonStore = defineStore('common',  {
    state: () => {
        return {
            routers: <RouteRecordRaw[]>[], // 路由对象
        }
    },
    actions: {
          setRouters(params:any) {
            this.routers = params;
            for(const i in params){
                router.addRoute(params[i])
            }
          }
    }
})

export default CommonStore;

