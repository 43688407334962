import { App, Directive } from 'vue';
import UserStore from '@/store/user'

export const Auth: Directive = {
    mounted(el, binding) {
        const userStore = UserStore();
        const actionName = binding.value || '';
        const permissions = userStore.permissions;
        const [requiredPermission, requiredAction] = actionName.split('.');
        if (
            !(
                permissions[requiredPermission]
                && requiredAction
                && permissions[requiredPermission].includes(requiredAction)
            )
        ) {
            if (el.parentNode) {
                el.parentNode.removeChild(el);
            } else {
                el.style.display = 'none';
            }
        }
    }
};

const main = (app: App) => {
    app.directive('auth', Auth)
}

export default main;
